import ReactGA from 'react-ga';

const trackingId = 'G-MXKBZQJYEJ'; // Morgen Green tracking ID

ReactGA.initialize(trackingId, {
  debug: process.env.NODE_ENV !== 'production',

  gaOptions: {
    page_title: document.title,
    page_path: window.location.pathname,
  },
});


// TRACKING PAGE VIEW
export const trackPageView = () => {
  ReactGA.set({ page_title: document.title, page_path: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

// TRACKING EVENTS
export const trackEvent = (category, action, label, value) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};