import React from 'react';
import Logo from '../../src/assets/images/loaderLogo.png'

const Loader = () => {
  return (
    <div className="flex justify-center items-center w-full h-screen">
      <img
        src={Logo}
        alt="Logo"
        className="w-32 h-32 loading-logo shadow-none bg-transparent xl:w-36 xl:h-36 2xl:w-40 2xl:h-40"
      />
    </div>
  );
};

export default Loader
